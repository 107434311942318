@import url("../Base.css");

.ender {
    font-family: var(--nav-font-family);
    font-size: var(--nav-font-size);
    color: var(--primary-text);
    padding-bottom: 1rem;
}

@media only screen and (max-width: 700px) {
    .ender {
        font-size: 0.7rem;
    }
}
