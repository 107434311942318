@import url("./Base.css");

.App {
  text-align: center;
  background-repeat: no-repeat;
  /*background-attachment: fixed;*/
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(
    160deg,
    var(--primary-dark),
    var(--primary-dark),
    var(--primary-medium)
  );
  min-height: 100vh;
  padding-top: 2rem;
  overflow-x: hidden;
}

.background-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.polygon {
  position: absolute;
  width: 30px; /* Adjust the size of your polygons */
  height: 30px;
  background-color: rgba(11, 253, 181, 0.07); /* Adjust brightness as needed */
  transform-origin: 50% 50%;
  border-radius: 50%;
  opacity: 0; /* Initially set opacity to 0 */
  animation: fadeIn 1s ease-out 1s forwards; /* Animation properties */
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.parallax {
  transition: transform 0.1s ease-out; /* Add a smooth transition effect */
}

.pageContainer {
  padding: 0 0 0 0;
}

body::-webkit-scrollbar {
  width: 0em;
}

@media only screen and (max-width: 1100px) {
  .App {
    padding-top: 2rem;
  }

  .resumeContainer {
    top: 25vh;
    left: 0;
    margin: 1rem 1rem 0 0;
    animation: slideInRightAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

@media only screen and (max-width: 700px) {
  .resumeContainer {
    top: 25vh;
    right: unset;
    left: 0;
    margin: 1.4rem;
    animation: slideInRightAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

@keyframes slideInRightAnimation {
  0% {
    opacity: 0;
    transform: translateX(2rem);
  }
  50% {
    opacity: 0;
    transform: translateX(2rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
