@import url("../Base.css");

.timelineContainer {
    padding: 1rem 25vw 0 25vw;
    font-family: var(--font-family);
    color: var(--primary-text);
}

.employment {
    align-self: flex-start;
    justify-content: center;
    padding: 0.3rem 1rem 0.3rem 1rem;
    font-size: 1.3rem;
    width: max-content;
    line-height: 2em;
    border-radius: .7rem;
    background-color: var(--contrast);
    color: var(--primary-dark);
}

.employmentSection {
    display: flex;
    flex-direction: row;
}

.employmentLine {
    margin: 0 0 0 3.05rem;
    flex: 0 0 0.2em;
    background-color: var(--contrast);
}

.employmentHeading {
    font-size: 4rem;
    text-align: left;
    padding: 0 0 0 1.7rem;
}

.employmentSubHeading {
    font-size: 1.2rem;
    text-align: left;
    padding: 0 0 0 2rem;
}

.education {
    align-self: flex-end;
    justify-content: center;
    padding: 0.3rem 1rem 0.3rem 1rem;
    font-size: 1.3rem;
    width: max-content;
    line-height: 2em;
    border-radius: .7rem;
    background-color: var(--contrast);
    color: var(--primary-dark);
}

.educationSection {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.educationLine {
    margin: 0 3.05rem 0 0;
    flex: 0 0 0.2em;
    background-color: var(--contrast);
}

.educationHeading {
    font-size: 4rem;
    text-align: right;
    padding: 0 1.7rem 0 0;
}

.educationSubHeading {
    font-size: 1.2rem;
    text-align: right;
    padding: 0 2rem 0 0;
}

@media only screen and (max-width: 1100px) {
    .timelineContainer {
        padding: 1rem 10vw 0 10vw;
        font-family: var(--font-family);
        color: var(--primary-text);
    }
}


@media only screen and (max-width: 700px) {
    .timelineContainer {
        padding: 1rem 7vw 0 7vw;
        font-family: var(--font-family);
        color: var(--primary-text);
    }

    .employment {
        align-self: flex-start;
        justify-content: center;
        padding: 0.3rem 1rem 0.3rem 1rem;
        font-size: 1.3rem;
        width: max-content;
        line-height: 2em;
        border-radius: .7rem;
    }

    .employmentLine {
        margin: 0 0 0 1.2rem;
        flex: 0 0 0.2em;
    }

    .employmentHeading {
        font-size: 1.4rem;
        text-align: left;
        padding: 0 0 0 1.1rem;
    }

    .employmentSubHeading {
        font-size: 0.8rem;
        text-align: left;
        padding: 0 0 0 1.2rem;
    }

    .education {
        align-self: flex-end;
        justify-content: center;
        padding: 0.3rem 1rem 0.3rem 1rem;
        font-size: 1.3rem;
        width: max-content;
        line-height: 2em;
        border-radius: .7rem;
    }

    .educationLine {
        margin: 0 1.2rem 0 0;
        flex: 0 0 0.2em;
    }

    .educationHeading {
        font-size: 1.4rem;
        text-align: right;
        padding: 0 1.1rem 0 0;
    }

    .educationSubHeading {
        font-size: 0.8rem;
        text-align: right;
        padding: 0 1.2rem 0 0;
    }
}

@keyframes fadeInAnim {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
