@import url("../Base.css");

.topSpacer {
  height: 20vh;
}

.projectItemContainer {
  width: 75vw;
  margin: auto auto 25vh;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-radius: 2vw;
  -moz-box-shadow: 0 0 100px rgba(34, 36, 51, 0.82);
  -webkit-box-shadow: 0 0 100px rgba(34, 36, 51, 0.82);
  box-shadow: 0 0 100px rgba(34, 36, 51, 0.82);
  cursor: pointer;
}

.imageContainer {
  flex: 0 0 45%;
  position: relative;
}

.projectImage {
  width: 100%;
  border-radius: 2vw 0px 0px 2vw;
  display: block;
}

.projectText {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--primary-text);
  font-family: var(--font-family);
  align-items: center;
  background-color: rgba(11, 53, 70, 0.97);
  border-radius: 0px 2vw 2vw 0px;
  padding: 5vh 3vw 3.5vh 3vw;
}

.projectTitle {
  font-size: 2rem;
  font-size: 2vw;
  text-align: left;
  width: 100%;
}

.projectDescription {
  text-align: left;
  font-size: 0.9rem;
  font-size: 0.8vw;
}

.techList {
  width: 100%;
  display: flex;
  flex-direction: row;
  color: rgba(255, 255, 255, 0.65);
  justify-content: space-around;
  font-size: 0.7rem;
  font-size: 0.6vw;
  padding-top: 15px;
}

@media only screen and (max-width: 1100px) {
  .projectItemContainer {
    flex-direction: column;
  }

  .projectImage {
    border-radius: 2vw 2vw 0 0;
  }

  .projectText {
    border-radius: 0 0 2vw 2vw;
  }

  .projectTitle {
    font-size: 3vw;
    padding-bottom: 2vh;
  }

  .projectDescription {
    font-size: 1.5vw;
    padding-bottom: 2vh;
  }

  .techList {
    font-size: 1.2vw;
  }
}

@media only screen and (max-width: 700px) {

  .projectImage {
    border-radius: 5vw 5vw 0 0;
  }

  .projectText {
    padding: 3vh 7vw 3vh 7vw;
    border-radius: 0 0 5vw 5vw;
  }

  .projectItemContainer {
    margin: auto auto 10vh;
    border-radius: 5vw 5vw 5vw 5vw;
  }

  .projectTitle {
    font-size: 5vw;
    padding-bottom: 2vh;
  }

  .projectDescription {
    font-size: 2.4vw;
    padding-bottom: 2vh;
  }

  .techList {
    font-size: 2vw;
  }
}
