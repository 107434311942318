@import url("../Base.css");

.mainHeading {
  text-align: center;
  width: 100vw;
  line-height: 7vh;
  font-size: 5vw;
}

.subHeading {
  text-align: center;
  width: 100vw;
  color: var(--contrast);
  line-height: 3vh;
  font-size: 1vw;
}

.intro {
  margin-top: -1.5vh;
  text-align: center;
  width: 100vw;
  color: var(--primary-text);
  font-size: 0.80vw;
}

.resumeContainer {
  width: 7vw;
  padding: 3vh 0 8vh 46.5VW;
  animation: slideInRightAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@media only screen and (max-width: 1100px) {
  .mainHeading {
    font-size: 10vw;
  }

  .subHeading {
    font-size: 3vw;
  }

  .intro {
    font-size: 2vw;
  }

  .resumeContainer {
    width: 10vw;
    padding-left: 45vw;
    padding-top: 1vh;
    padding-bottom: 8vh;
  }
}

@media only screen and (max-width: 700px) {
  .mainHeading {
    font-size: 12vw;
  }

  .subHeading {
    font-size: 4vw;
  }

  .intro {
    padding-top: 1vh;
    font-size: 2.4vw;
  }

  .resumeContainer {
    width: 20vw;
    padding-left: 36vw;
    padding-top: 0;
    padding-bottom: 6vh;
  }
}
