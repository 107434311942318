@import url("../Base.css");


.noRouteCont {
    height: 80vh;
    width: 100%;
    justify-content: center;
    align-content: center;
    padding: 0 0 0 0;
}
