@import url("../Base.css");

.buttonContainer {
  background-color: rgba(32, 80, 114, 0.89);
  align-itmes: center;
  justify-content: center;
  border: 0.05rem solid var(--contrast);
  border-radius: 0.6vw;
  padding: 1vh 1vw;
  font-size: var(--nav-font-size);
  font-size: 1.2vw;
  font-family: var(--nav-font-family);
  color: var(--contrast);
  cursor: pointer;
  line-height: 1;
  transition: var(--transition);
}

.buttonContainer:hover {
  background-color: var(--contrast);
  color: var(--primary-dark);
}

@media only screen and (max-width: 1100px) {
  .buttonContainer {
    font-size: 2vw;
  }
}
@media only screen and (max-width: 700px) {
  .buttonContainer {
    font-size: 4vw;
    border-radius: 1.6vw;
  }
}
