@import url("../Base.css");

.socialsContainer {
  width: 5vw;
  position: fixed;
  bottom: 0;
  transition: linear 0.7s;
}

.socialsItem {
  justify-content: center;
  margin-bottom: 1.6vh;
  color: var(--contrast);
  cursor: pointer;
  font-size: 1.4vw;
}

@media only screen and (max-width: 1100px) {
  .socialsContainer {
    width: 9vw;
  }

  .socialsItem {
    font-size: 2vw;
  }
}

.line {
  margin-left: auto;
  margin-right: auto;
  width: 0.05vw;
  height: 8vh;
  background-color: var(--contrast);
}

.hiddenSoc {
  transition: linear 0.2s;
  transform: translateY(60vh);
}

.activeSoc {
  transform: translateY(0rem);
}
